import React, { useEffect, useRef, useState } from "react"
import { SvgSprite } from "../../ui/SvgSprite/SvgSprite"
import styles from "./index.module.scss"
import UserSection from "./UserSection"
import { axiosInstance } from "../../config/https"
import { useDispatch, useSelector } from "react-redux"
import UserActivitiesCard from "./UserActivitiesCard"
import cn from "classnames"

// custom hook
import { useInput } from "../../hooks/useInput"
import { setUpdatedOrgUser } from "../../../store/slices/orgSlice"
import { Input } from "../../ui/Input/Input"
import { Select } from "../../ui/Select/Select"
import { Img } from "../../ui/Img/Img"
import { Loader } from "../../ui/Loader/Loader"

// QRCode
import QRCode from "qrcode.react"
import { toast } from "../../functions/toast"
import { Btn } from "../../ui/Btn"
import { checkErrors } from "../../functions/checkErrors"
import { setUserInfo } from "../../../store/slices/userSlice"
import { getFrameworks } from "../../functions/getFrameworks"

const ProfileSection = ({ userProfile, access, withBack = true, activities }) => {
	const [UserActivities, setUserActivities] = useState([])
	const [inputAccess, setInputAccess] = useState(false)
	const [invite, setInvite] = useState(false)
	const [errors, setErrors] = useState({})
	const dispatch = useDispatch()
	const dropdownRef = useRef(null)

	const frameworks = useSelector(state => state.framework.frameworks)
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [selectedFrameworks, setSelectedFrameworks] = useState([])

	const currentUser = useSelector(state => state.user.userInfo)
	const currentUserQrCode = useSelector(state => state.user.qrCodeUrl)
	const [qrCodeUrl, setQrCodeUrl] = useState(null)
	//inputs value

	const firstName = useInput(userProfile?.firstName || "")
	const lastName = useInput(userProfile?.lastName || "")
	const email = useInput(userProfile?.email || "")
	const fullName = useInput("")
	const phone = useInput(userProfile?.phone || "")
	const password = useInput(userProfile?.password || "")

	// styles configuration
	const [profileClick, setProfileClick] = React.useState(!activities)
	const [activityClick, setActivityClick] = React.useState(activities)
	const [newRole, setNewRole] = useState(
		userProfile && userProfile?.role?.charAt(0).toUpperCase() + userProfile?.role?.slice(1),
	)
	const [back, setBack] = React.useState(false)
	const [file, setFile] = useState(null)
	const [loader, setLoader] = useState(false)
	const [new2FA, setNew2FA] = useState(userProfile && userProfile?.two_factor_type)
	const [loading, setLoading] = useState(false)
	const containerRef = useRef(null)
	const [checkboxes, setCheckboxes] = useState({
		ownerNotif: false,
		stakeholderNotif: false,
		incidentReportNotif: false,
		eventTypeNotif: false,
		eventNotif: false,
		roleNotif: false,
		commentNotif: false,
	})

	const [roles] = useState([
		{
			id: 0,
			name: "Admin",
			value: "Admin",
		},
		{
			id: 1,
			name: "Manager",
			value: "Manager",
		},
		{
			id: 2,
			name: "Member",
			value: "Member",
		},
		{
			id: 3,
			name: "User",
			value: "User",
		},
		{
			id: 4,
			name: "Auditor",
			value: "auditor",
		},
	])

	const [_2FA] = useState([
		{
			id: 1,
			name: "Disabled",
			value: "disabled",
		},
		{
			id: 2,
			name: "Email",
			value: "email",
		},
		{
			id: 3,
			name: "Google Authentication",
			value: "google",
		},
	])

	const handleUpdateUser = async () => {
		setLoader(true)
		const data = {
			firstName: firstName.value,
			lastName: lastName.value,
			email: email.value,
			phone: phone.value,
			role: newRole,
			password: password.value,
			image: file,
		}

		if (file) {
			const fileData = {
				image: file,
			}
			await axiosInstance
				.post(`/update/user/${userProfile?.id}/image`, fileData, {
					headers: {
						"content-type": "multipart/form-data",
					},
				})
				.then(() => {
					// dispatch(toast('success', 'User Updated Successfully'))
				})
				.catch(err => {
					dispatch(toast("error", err?.response?.data?.message))
				})
				.finally(() => {
					setLoader(false)
				})
		}
		const updateData = JSON.stringify(data)
		await axiosInstance
			.patch(`/users/${userProfile?.id}`, updateData, {
				headers: {
					"content-type": "application/json",
				},
			})
			.then(res => {
				if (userProfile?.id === currentUser?.id) {
					dispatch(setUserInfo(res.data.data))
				} else dispatch(setUpdatedOrgUser(res.data.data))

				// Update 2FA
				change2FA()
				dispatch(toast("success", "User Updated Successfully"))
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
			})
			.finally(() => {
				setLoader(false)
			})
		setLoader(false)
		withBack && setBack(true)
	}

	// get user activities
	React.useEffect(() => {}, [activityClick, dispatch, userProfile?.id])

	const BackHandler = () => {
		setBack(true)
	}

	const profileClickHandler = () => {
		setProfileClick(true)
		setActivityClick(false)
	}

	const activityClickHandler = () => {
		setActivityClick(true)
		setProfileClick(false)
	}
	const handlerNameActivity = name => {
		const nameActivity = name.split("_")
		return nameActivity[0].toLowerCase() + " " + nameActivity[1].toLowerCase()
	}

	const switchProfile = {
		color: profileClick ? "#DB493C" : "#E0E0E0",
		cursor: "pointer",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottom: profileClick ? "2px solid rgb(219, 73, 60)" : "2px solid transparent",
	}
	const switchActivity = {
		color: activityClick ? "#DB493C" : "#E0E0E0",
		marginLeft: "10px",
		cursor: "pointer",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottom: activityClick ? "2px solid rgb(219, 73, 60)" : "2px solid transparent",
	}

	const change2FA = async () => {
		const data = {
			type: new2FA,
		}
		await axiosInstance
			.post(`/update/user/${userProfile?.id}/2fa`, data)
			.then(() => {
				// dispatch(toast('success', '2FA Updated Successfully'))
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
			})
	}

	useEffect(() => {
		(async () => {
			if (new2FA === "google") {
				await axiosInstance
					.get(`/qrcode/user/${userProfile.email}`)
					.then(res => {
						setQrCodeUrl(res.data.qr_code_url)
					})
					.catch(err => {
						dispatch(toast("error", err?.response?.data?.message))
					})
			}
		})()
	}, [dispatch, new2FA, userProfile])

	const handelInvite = async () => {
		setInvite(true)
		try {
			const data = {
				email: email.value,
				role: newRole,
				fullName: fullName.value,
				frameworksIds: selectedFrameworks,
			}

			const inputsInfo = [
				{ el: email, errorName: "email", errorText: "Please Enter Email" },
				{ el: fullName, errorName: "fullName", errorText: "Enter FullName" },
			]

			if (await checkErrors(setErrors, inputsInfo)) {
				setInvite(false)
				return
			}

			await axiosInstance
				.post("/auth/invite/send", data)
				.then(() => {
					dispatch(toast("success", "Invite Send With Success"))
				})
				.catch(err => {
					if (err?.response?.data?.errors) {
						for (const [key, value] of Object.entries(err?.response?.data?.errors)) {
							dispatch(toast("error", `${key}: ${value}`))
						}
						if (err?.response?.data?.message) dispatch(toast("error", err?.response?.data?.message))
					} else dispatch(toast("error", err?.response?.data?.message))
				})
				.finally(() => {
					setInvite(false)
				})
		} catch (error) {
			console.log(error)
			setInvite(false)
		}
		setInvite(false)
	}

	useEffect(() => {
		setInputAccess(access ? currentUser?.id !== userProfile?.id : access)
	}, [currentUser, userProfile, access])

	const getActivities = () => {
		(async () => {
			try {
				axiosInstance
					.get(
						`/controls/activities/user?user_id=${userProfile?.id}&offset=${UserActivities?.length || 0}&limit=${8}`,
						{
							header: {
								"Content-Type": "application/json",
							},
						},
					)
					.then(async res => {
						if (res.status === 200) {
							setUserActivities([...UserActivities, ...res.data.activities])
							setLoading(false)
						}
					})
					.catch(err => {
						dispatch(toast("error", err?.response?.data?.message))
					})
			} catch (error) {
				console.log(error)
			}
		})()
	}

	const handleScroll = event => {
		const container = event.target
		const scrollHeight = container.scrollHeight
		const scrollTop = container.scrollTop
		const clientHeight = container.clientHeight
		if (scrollHeight - scrollTop === clientHeight) {
			setLoading(true)
			getActivities()
		}
	}

	const handleCheckboxChange = event => {
		const { name, checked } = event.target
		setCheckboxes(prevCheckboxes => ({
			...prevCheckboxes,
			[name]: checked,
		}))

		// Send PATCH request here
		axiosInstance
			.patch("update/notification/center", { [name]: checked })
			.then(response => {
				// Handle response if needed
			})
			.catch(error => {
				// Handle error if needed
			})
	}

	const getEmailNotif = async () => {
		await axiosInstance("notification/center")
			.then(res => {
				setCheckboxes({
					ownerNotif: res.data.data.owner_notif,
					stakeholderNotif: res.data.data.stakeholder_notif,
					incidentReportNotif: res.data.data.incident_report_notif,
					eventTypeNotif: res.data.data.event_type_notif,
					eventNotif: res.data.data.event_notif,
					roleNotif: res.data.data.role_notif,
					commentNotif: res.data.data.comment_notif,
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	useEffect(() => {
		(async () => {
			getActivities()
			getEmailNotif()
			await dispatch(getFrameworks())
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (activities) {
			activityClickHandler()
		}
	}, [activities])

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen)
	}

	const handleCheckbox = id => {
		if (selectedFrameworks.includes(id)) {
			setSelectedFrameworks(selectedFrameworks.filter(f => f !== id))
		} else {
			setSelectedFrameworks([id])
		}
	}

	const handleClickOutside = event => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownOpen(false)
		}
	}

	// Add event listener when component mounts
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [])

	return (
		<>
			{!back && (
				<>
					<div className={styles.headerModel}>
						<ul style={{ display: "flex", alignItems: "center" }}>
							<li className={styles.memberIconText}>
								<SvgSprite
									className={styles.settingsIcon}
									spriteID={"members"}
								/>
								{!userProfile && <div>Create User</div>}
							</li>
							<li style={{ color: "#E0E0E0" }}>{userProfile?.name}</li>
						</ul>
						<div style={{ display: "flex", alignItems: "center", marginLeft: " 15px", height: "100%", gap: "20px" }}>
							<div
								style={switchProfile}
								onClick={profileClickHandler}
							>
								{" "}
								Profile{" "}
							</div>
							{userProfile && (
								<div
									style={switchActivity}
									onClick={activityClickHandler}
								>
									{" "}
									Activity{" "}
								</div>
							)}
						</div>
						{userProfile ? (
							<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
								{withBack && (
									<ul
										onClick={() => BackHandler()}
										className={cn(styles.button, styles.settingIcon)}
									>
										<li>Back</li>
									</ul>
								)}
								{!inputAccess && (
									<Btn
										className={styles.AddUserBtn}
										disable={loader}
										onClick={() => {
											!loader && handleUpdateUser()
										}}
									>
										<SvgSprite
											className={styles.AddUserBtnIcon}
											spriteID={"add"}
										></SvgSprite>
										{loader ? <Loader /> : <div>Update User</div>}
									</Btn>
								)}
							</div>
						) : (
							<div></div>
						)}
					</div>
					{userProfile ? (
						<div
							className={cn(styles.containerProfileActivity, {
								[styles.containerProfile]: !activityClick,
							})}
						>
							<div
								className={cn(styles.profileBody, {
									[styles.containerProfileNotif]: !activityClick,
								})}
							>
								<div className={styles.card}>
									<div style={{ height: "150px", width: "150px", borderRadius: "50%" }}>
										<div className={styles.uploadUserImage}>
											<div className={styles.formUpUser}>
												{((!userProfile && file) || (userProfile && file)) && (
													<img
														src={URL.createObjectURL(file)}
														alt={"user logo"}
														className={styles.imageUsers}
													/>
												)}
												{((!userProfile && !file) || (userProfile && !userProfile?.image && !file)) && !inputAccess && (
													<>
														<input
															type="file"
															accept="image/*"
															onChange={e => setFile(e.target.files[0])}
														/>
														<p className={styles.guide}>
															<SvgSprite
																spriteID={"image"}
																className={styles.uploadIcon}
															/>
														</p>
														<p className={styles.commentsUser}>Add Photo</p>
													</>
												)}
												{userProfile?.image && !file && (
													<Img
														path={`public/${userProfile?.image}`}
														img
														className={styles.imageUsers}
													/>
												)}
											</div>
											<div className={styles.uploadUser}>
												<div className={styles.UserFormUoImage}>
													{!inputAccess && (
														<>
															<input
																type="file"
																accept="image/*"
																onChange={e => setFile(e.target.files[0])}
															/>
															<p className={styles.imagecommentUser}>Upload New Image</p>
														</>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className={styles.nameContainer}>
										<span style={{ fontWeight: "bold", color: "#6B7A99", fontSize: "14px" }}>
											{userProfile?.firstName} {userProfile?.lastName}
										</span>
										<span style={{ color: "#BDBDBD", fontSize: "12px" }}>{userProfile?.role}</span>
									</div>
								</div>
								{profileClick && (
									<div className={styles.info}>
										<div className={styles.headInfo}>
											<span style={{ color: "#E0E0E0", fontSize: "12px" }}>Profile</span>
											<div>
												<SvgSprite
													className={styles.IconNoBackgroundColor}
													spriteID={"more"}
												></SvgSprite>
											</div>
										</div>
										<div className={styles.formInfo}>
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													alignContent: "center",
													flexWrap: "wrap",
													gap: "5%",
												}}
											>
												<div className={styles.customInput}>
													<label className={styles.label}>First Name</label>
													<Input
														className={styles.inputUser}
														value={firstName.value}
														onChange={firstName.onChange}
														type={"text"}
														disabled={inputAccess}
													/>
												</div>
												<div className={styles.customInput}>
													<label className={styles.label}>Last Name</label>
													<Input
														className={styles.inputUser}
														value={lastName.value}
														onChange={lastName.onChange}
														type={"text"}
														disabled={inputAccess}
													/>
												</div>
												<div className={styles.customInput}>
													<label className={styles.label}>Role</label>

													<div style={{ position: "relative" }}>
														{access || (!access && currentUser?.id === userProfile?.id) ? (
															<Input
																className={styles.inputUser}
																value={newRole || ""}
																onChange={() => console.log("")}
																type={"text"}
																disabled={access || (!access && currentUser?.id === userProfile?.id)}
															/>
														) : (
															<Select
																selectFullWidth
																className={styles.selectRole}
																setNewValue={setNewRole}
																initValue={newRole}
																values={roles}
															/>
														)}
													</div>
												</div>
												<div className={styles.customInput}>
													<label className={styles.label}>Phone</label>
													<Input
														className={styles.inputUser}
														value={phone.value}
														onChange={phone.onChange}
														type={"text"}
														disabled={inputAccess}
													/>
												</div>
												<div className={styles.customInput}>
													<label className={styles.label}>Email</label>
													<Input
														className={styles.inputUser}
														value={email.value}
														onChange={email.onChange}
														type={"email"}
														disabled={inputAccess}
													/>
												</div>
												{(currentUser?.id === userProfile?.id || !access) && (
													<>
														<div className={styles.customInput}>
															<label className={styles.label}>Password</label>
															<Input
																className={styles.inputUser}
																value={password.value}
																onChange={password.onChange}
																type={"password"}
																disabled={inputAccess}
															/>
														</div>

														{userProfile && (
															<div className={styles.customInput}>
																<label className={styles.label}>2FA</label>
																<div style={{ position: "relative", textAlign: "left" }}>
																	{inputAccess ? (
																		<Input
																			className={styles.inputUser}
																			value={new2FA || ""}
																			onChange={() => console.log("")}
																			type={"text"}
																			disabled={inputAccess}
																		/>
																	) : (
																		<Select
																			selectFullWidth
																			className={styles.selectRole}
																			setNewValue={setNew2FA}
																			initValue={new2FA}
																			values={_2FA}
																		/>
																	)}
																	{new2FA === "google" &&
																		(qrCodeUrl || (currentUser.id === userProfile.id && currentUserQrCode)) && (
																		<div style={{ textAlign: "center", marginLeft: "15px" }}>
																			<p className={styles.headerStyleText}>
																					Scan the QR code below with Google Authenticator :
																			</p>
																			<QRCode
																				value={currentUser.id === userProfile.id ? currentUserQrCode : qrCodeUrl}
																			/>
																		</div>
																	)}
																</div>
															</div>
														)}
													</>
												)}
											</div>
										</div>
									</div>
								)}
								{activityClick && (
									<div className={styles.archive}>
										<div className={styles.headInfo}>
											<span style={{ color: "#E0E0E0", fontSize: "12px", fontWeight: "bold" }}>
												LAST ACTIVITY BY TASK
											</span>
										</div>
										<div
											className={cn(styles.archiveBody, styles.containerListUser)}
											ref={containerRef}
											onScroll={handleScroll}
										>
											{UserActivities?.map((activity, index) => (
												<UserActivitiesCard
													key={index}
													activity={activity}
													handlerNameActivity={handlerNameActivity}
												/>
											))}
											{loading && (
												<div className={styles.loaderCenter}>
													<Loader />
												</div>
											)}
										</div>
									</div>
								)}
							</div>
							{!activityClick && currentUser?.id === userProfile?.id && (
								<div className={cn(styles.profileBodyNotification)}>
									<div className={cn(styles.card, styles.cardNotification)}></div>
									<div className={cn(styles.info, styles.infoNotification)}>
										<div className={styles.centerNotificationHeader}> Email Notifications </div>
										<div className={styles.centerNotificationbody}>
											<div className={styles.checkboxDiv}>
												<input
													className={styles.checkboxNotif}
													type="checkbox"
													name="ownerNotif"
													checked={checkboxes.ownerNotif}
													onChange={handleCheckboxChange}
												/>
												<label className={styles.checkboxNotifLabel}>X has added/removed you as a control owner</label>
											</div>
											<div className={styles.checkboxDiv}>
												<input
													className={styles.checkboxNotif}
													type="checkbox"
													name="stakeholderNotif"
													checked={checkboxes.stakeholderNotif}
													onChange={handleCheckboxChange}
												/>
												<label className={styles.checkboxNotifLabel}>X has added/removed you as a stakeholder</label>
											</div>
											<div className={styles.checkboxDiv}>
												<input
													className={styles.checkboxNotif}
													type="checkbox"
													name="incidentReportNotif"
													checked={checkboxes.incidentReportNotif}
													onChange={handleCheckboxChange}
												/>
												<label className={styles.checkboxNotifLabel}>
													X has added/removed you to an incident report
												</label>
											</div>
											<div className={styles.checkboxDiv}>
												<input
													className={styles.checkboxNotif}
													type="checkbox"
													name="eventTypeNotif"
													checked={checkboxes.eventTypeNotif}
													onChange={handleCheckboxChange}
												/>
												<label className={styles.checkboxNotifLabel}>
													Calendar invites and type general, audit, review, (link to calendar event)
												</label>
											</div>
											<div className={styles.checkboxDiv}>
												<input
													className={styles.checkboxNotif}
													type="checkbox"
													name="eventNotif"
													checked={checkboxes.eventNotif}
													onChange={handleCheckboxChange}
												/>
												<label className={styles.checkboxNotifLabel}>
													Calendar Events you are added to when they are approaching
												</label>
											</div>
											<div className={styles.checkboxDiv}>
												<input
													className={styles.checkboxNotif}
													type="checkbox"
													name="roleNotif"
													checked={checkboxes.roleNotif}
													onChange={handleCheckboxChange}
												/>
												<label className={styles.checkboxNotifLabel}>
													If an Admin changes their status (user to member, member to admin, admin to manager, etc..)
												</label>
											</div>
											{/* <div className={styles.checkboxDiv}>
                            <input
                            className={styles.checkboxNotif}
                            type="checkbox"
                            name="commentNotif"
                            checked={checkboxes.commentNotif}
                            onChange={handleCheckboxChange}
                            />
                             <label className={styles.checkboxNotifLabel}>X has mentioned you in a comment (link to comment)</label>
                        </div> */}
										</div>
									</div>
								</div>
							)}
						</div>
					) : (
						<div className={styles.invitForm}>
							<div className={styles.InvitFormInfo}>
								<div className={styles.customInput}>
									<label className={styles.label}>Full name</label>
									<Input
										className={styles.inputUser}
										value={fullName.value}
										onChange={fullName.onChange}
										type={"text"}
										error={errors.fullName}
										disabled={access}
									/>
								</div>
								<div className={styles.customInput}>
									<label className={styles.label}>Email</label>
									<Input
										className={styles.inputUser}
										value={email.value}
										onChange={email.onChange}
										type={"email"}
										error={errors.email}
										disabled={access}
									/>
								</div>
								<div className={styles.customInput}>
									<label className={styles.label}>Role</label>
									<Select
										selectFullWidth
										className={styles.selectRole}
										setNewValue={setNewRole}
										initValue={newRole}
										values={roles}
									/>
								</div>
								{newRole === "auditor" && (
									<div className={styles.customInput}>
										<label className={styles.label}>Frameworks</label>
										<div className={styles.selectRole}>
											<div
												className={styles.dropdownHeader}
												onClick={toggleDropdown}
											>
												<div className={styles.dropdownSelected}>
													{selectedFrameworks.length > 0
														? frameworks?.find(f => f.id === selectedFrameworks[0])?.name
														: "Select Frameworks"}
												</div>
												<div className={styles.dropdownIcon}></div>
											</div>

											<div
												ref={dropdownRef}
												className={cn(styles.dropdownContent, { [styles.dropdownContentHide]: !dropdownOpen })}
											>
												{(frameworks || []).map((f, i) => (
													<div
														key={i}
														className={styles.dropdownItem}
													>
														<input
															type="radio"
															id={`framework_${f.id}`}
															name="frameworks[]"
															value={f.id}
															className={styles.checkbox}
															onChange={() => handleCheckbox(f.id)}
															checked={selectedFrameworks.includes(f.id)}
														/>
														<label
															htmlFor={`framework_${f.id}`}
															className={styles.checkboxLabel}
														>
															{f.name}
														</label>
													</div>
												))}
												{!frameworks && (
													<div className={styles.frameworksLoader}>
														<Loader />
													</div>
												)}
											</div>
										</div>
									</div>
								)}
							</div>
							<div className={styles.listInvitBtn}>
								<Btn
									className={styles.btnInvitCancel}
									onClick={() => BackHandler()}
								>
									Cancel
								</Btn>
								<Btn
									className={styles.btnInvit}
									disable={invite}
									onClick={() => handelInvite()}
								>
									<div>Invite</div>
									{invite && <Loader />}
								</Btn>
							</div>
						</div>
					)}
				</>
			)}

			{back && <UserSection access={access} />}
		</>
	)
}

export default ProfileSection
