import React, { useEffect, useRef, useState } from "react"
import { Popup } from "../../ui/Popup"
import { AddEventComponent } from "./AddEventComponent"
import styles from "./index.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { getEvents } from "../../functions/getEvents"
import { Btn } from "../../ui/Btn"
import { SettingModel } from "../SettingsPopup"
import cn from "classnames"
import { SvgSprite } from "../../ui/SvgSprite"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridDay from "@fullcalendar/timegrid"
import timeGridWeek from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import { axiosInstance } from "../../config/https"
import { toast } from "../../functions/toast"
import { CalendarWidget } from "../CalendarWidget"
import list from "../../../assets/images/calendar/list.png"
import calendar from "../../../assets/images/calendar/calendar.png"
import styled from "styled-components"

export const Calendar = () => {
	const allEvents = useSelector(state => state.events.events)
	const accountPlan = useSelector(state => state.organization.org.account_plan)

	const [addSettingPopup, setAddSettingPopup] = useState(false)
	const [plan, setPlan] = useState(false)
	const location = useLocation()
	const [stateData] = useState(location.state)
	const navigate = useNavigate()
	const [events, setEvents] = useState([])
	const [addEventPopup, setAddEventPopup] = useState()
	const [newEvent, setNewEvent] = useState()
	const [updateEvent, setUpdateEvent] = useState(false)
	const [openWithData, setOpenWithData] = useState()
	const dispatch = useDispatch()
	const [returnReportId, setReturnReportId] = useState(null)
	const [dropEvent, setDropEvent] = useState(false)
	const [isCalendarView, setIsCalendarView] = useState(true)
	const [dropFilter, setDropFilter] = useState(false)
	const [dropSort, setDropSort] = useState(false)
	const [dropStatus, setDropStatus] = useState(false)
	const [dropType, setDropType] = useState(false)

	const [sortBy, setSortBy] = useState("all")
	const [dropStatusValue, setDropStatusValue] = useState("all")
	const [dropTypeValue, setDropTypeValue] = useState("all")

	const dropdownRef = useRef()
	const dropdownRefList = useRef()
	const dropdownRefStatus = useRef()
	const dropdownRefType = useRef()

	const EventType = {
		"#8833FF": "general",
		"#E62E2E": "audit",
		"#33BFFF": "review",
		"#29CC39": "task",
		"#000000": "incident",
	}

	const types = {
		all: "All Events",
		general: "General",
		audit: "Audit",
		review: "Review",
		task: "Task",
		incident: "Incident",
	}

	const status = {
		all: "All status",
		pending: "Pending",
		completed: "Completed",
	}

	useEffect(() => {
		if (allEvents?.length > 0) {
			setEvents([
				...allEvents?.map(e => {
					return {
						id: e.id,
						allDay: false,
						createdBy: e.user_id,
						endHour: new Date(e.endTime).getTime(),
						startHour: new Date(e.startTime).getTime(),
						end: new Date(e.endTime),
						start: new Date(e.startTime),
						title: e.title,
						address: e.address,
						note: e.note,
						colorEvent: e.color,
						dataCreated: null,
						lastUpdated: null,
						members: e.members,
						completed_at: e.completed_at,
						attachments: e.attachments,
					}
				}),
			])
		}
	}, [allEvents])

	useEffect(() => {
		if (location.state) {
			setOpenWithData(true)
			setAddEventPopup(true)
		}
	}, [location])

	useEffect(() => {
		if (addEventPopup === false) {
			setOpenWithData(false)
			window.history.replaceState({}, document.title)
			navigate("/calendar")
			if (stateData?.report) {
				if (returnReportId) {
					navigate(`/report/${returnReportId}`)
				} else {
					navigate("/report/new")
				}
			} else if (stateData?.report_id) {
				navigate(`/report/${stateData?.report_id}`)
			} else if (stateData?.control?.id) {
				navigate(`/framework/control/${stateData?.control?.id}/submission`)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addEventPopup])

	useEffect(() => {
		const handleClickOutside = event => {
			// Close the dropdown if the click is outside the dropdown
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setDropEvent(!dropEvent)
			}
		}

		// Attach the event listener
		document.addEventListener("mousedown", handleClickOutside)

		// Detach the event listener when the component unmounts
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dropdownRef])

	useEffect(() => {
		(async () => {
			await Promise.all([dispatch(getEvents())])
		})()
	}, [dispatch])

	useEffect(() => {
		const currentDate = new Date()
		let tempEvents = allEvents

		if (sortBy !== "all") {
			if (sortBy === "week") {
				tempEvents = filterEventsByWeek(tempEvents, currentDate)
			}

			if (sortBy === "month") {
				tempEvents = filterEventsByMonth(tempEvents, currentDate)
			}

			if (sortBy === "year") {
				tempEvents = filterEventsByYear(tempEvents, currentDate)
			}
		}

		if (dropStatusValue === "completed") {
			tempEvents = tempEvents.filter(e => e.completed_at)
		}

		if (dropStatusValue === "pending") {
			tempEvents = tempEvents.filter(e => !e.completed_at)
		}

		if (dropTypeValue !== "all") {
			tempEvents = tempEvents.filter(e => EventType[e.color] === dropTypeValue)
		}

		setEvents([
			...tempEvents?.map(e => {
				return {
					id: e.id,
					allDay: false,
					createdBy: e.user_id,
					endHour: new Date(e.endTime).getTime(),
					startHour: new Date(e.startTime).getTime(),
					end: new Date(e.endTime),
					start: new Date(e.startTime),
					title: e.title,
					address: e.address,
					note: e.note,
					colorEvent: e.color,
					dataCreated: null,
					lastUpdated: null,
					members: e.members,
					completed_at: e.completed_at,
					attachments: e.attachments,
				}
			}),
		])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortBy, dropStatusValue, dropTypeValue, allEvents])

	const filterEventsByWeek = (events, currentDate) => {
		const startOfWeek = new Date(currentDate)
		startOfWeek.setHours(0, 0, 0, 0)
		startOfWeek.setDate(currentDate.getDate() - currentDate.getDay())
		const endOfWeek = new Date(startOfWeek)
		endOfWeek.setDate(endOfWeek.getDate() + 6)

		return events.filter(e => {
			const eventDate = new Date(e.startTime)
			return eventDate.getTime() >= startOfWeek.getTime() && eventDate.getTime() <= endOfWeek.getTime()
		})
	}

	const filterEventsByMonth = (events, currentDate) => {
		const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
		const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

		return events.filter(e => {
			const eventDate = new Date(e.startTime)
			return eventDate.getTime() >= startOfMonth.getTime() && eventDate.getTime() <= endOfMonth.getTime()
		})
	}

	const filterEventsByYear = (events, currentDate) => {
		const startOfYear = new Date(currentDate.getFullYear(), 0, 1)
		const endOfYear = new Date(currentDate.getFullYear(), 11, 31)

		return events.filter(e => {
			const eventDate = new Date(e.startTime)

			return eventDate.getTime() >= startOfYear.getTime() && eventDate.getTime() <= endOfYear.getTime()
		})
	}

	const handleEventDrop = info => {
		const { event, revert } = info
		const start = event._instance.range.start
		const end = event._instance.range.end

		const data = {
			title: event._def.title,
			note: event._def.extendedProps.note,
			color: event._def.extendedProps.colorEvent,
			end: new Date(end),
			start: new Date(start),
			startTime: new Date(start),
			endTime: new Date(end),
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			members: event._def.extendedProps.members?.map(a => {
				return a.id
			}),
			completed_at: event._def.extendedProps.completed_at ? true : false,
			attachmentsId: event._def.extendedProps.attachments?.map(a => {
				return a.id
			}),
			resourcesIds: event._def.extendedProps.resources?.map(a => {
				return a.id
			}),
		}

		axiosInstance
			.post(`event/update/${event.id}`, data)
			.then(async res => {
				const e = res.data.data
				const updatedEvent = {
					id: e.id,
					endHour: new Date(e.endTime).getTime(),
					startHour: new Date(e.startTime).getTime(),
					end: new Date(e.endTime),
					start: new Date(e.startTime),
					title: e.title,
					address: e.address,
					note: e.note,
					colorEvent: e.color,
					members: e.members,
					completed_at: e.completed_at,
					attachments: e?.attachments,
					resources: e?.resources,
				}

				const updatedEvents = events.map(existingEvent =>
					parseInt(existingEvent.id) === parseInt(event.id) ? updatedEvent : existingEvent,
				)

				setEvents(updatedEvents)
				dispatch(toast("success", "Event updated successfully"))
			})
			.catch(error => {
				dispatch(toast("error", "Error updating event: " + error))
				revert()
			})
	}

	const FullCalendarWrapper = styled.div`
		.fc .fc-button-primary:not(:disabled).fc-button-active {
			background-color: transparent !important;
			color: #DB493C !important;
			cursor: pointer !important;
		}
		.fc .fc-button-primary:disabled {
			background-color: transparent !important;
			color: #DB493C !important;
		}
		.fc-non-business {
			background-color: transparent !important;
		}
	`

	return (
		<div>
			{accountPlan?.toLowerCase() === "individual" ? (
				<div className={styles.planfeature}>
					<div className={styles.planfeature_text}>This feature is not available in your current plan</div>
					<Btn
						className={styles.upgradeBtn}
						onClick={() => {
							setAddSettingPopup(true)
							setPlan(true)
						}}
					>
						Upgrade Plan
					</Btn>
				</div>
			) : (
				<div className={styles.main}>
					<div className={styles.container}>
						<div className={styles.containerBtnCeareEvent}>
							<div className={cn(styles.headerList, styles.item)}>
								<div className={styles.resourcesHeaderIcon}>
									<div className={styles.headerWithFilter}>
										<div className={cn(styles.headerList, styles.item)}>
											<div className={cn(styles.resourcesHeaderIcon, styles.resourcesHeaderIconFilter)}>
												<div
													className={cn(styles.sortByDate, styles.FilterByDate)}
													onClick={() => setDropFilter(!dropFilter)}
												>
													<div>Filter</div>
													<SvgSprite
														spriteID={"filter"}
														className={styles.filterIcon}
													/>
												</div>
											</div>
										</div>
										{dropFilter && (
											<div className={styles.listOfFilter}>
												<div className={styles.resourcesHeaderIcon}>
													<div
														className={styles.sortByDate}
														onClick={() => setDropSort(!dropSort)}
													>
														<div> {sortBy === "all" ? "LifeTime" : `This ${sortBy}`} </div>
														<SvgSprite
															spriteID={"arrow"}
															className={cn(styles.arrowStyle, styles.iconMargin)}
														/>
													</div>
													{dropSort && (
														<div
															ref={dropdownRefList}
															className={cn(styles.actionsContent, styles.dropResourcActionsContent)}
														>
															<div
																className={cn(styles.dropResourceBtn)}
																onClick={() => {
																	setSortBy("all")
																	setDropSort(false)
																}}
															>
																<div> Lifetime </div>
																{sortBy === "all" && (
																	<SvgSprite
																		spriteID={"check"}
																		className={styles.checkStyles}
																	/>
																)}
															</div>
															<div
																className={cn(styles.dropResourceBtn)}
																onClick={() => {
																	setSortBy("week")
																	setDropSort(false)
																}}
															>
																<div> This week </div>
																{sortBy === "week" && (
																	<SvgSprite
																		spriteID={"check"}
																		className={styles.checkStyles}
																	/>
																)}
															</div>
															<div
																className={cn(styles.dropResourceBtn)}
																onClick={() => {
																	setSortBy("month")
																	setDropSort(false)
																}}
															>
																<div> This month </div>
																{sortBy === "month" && (
																	<SvgSprite
																		spriteID={"check"}
																		className={styles.checkStyles}
																	/>
																)}
															</div>
															<div
																className={cn(styles.dropResourceBtn)}
																onClick={() => {
																	setSortBy("year")
																	setDropSort(false)
																}}
															>
																<div> This year </div>
																{sortBy === "year" && (
																	<SvgSprite
																		spriteID={"check"}
																		className={styles.checkStyles}
																	/>
																)}
															</div>
														</div>
													)}
												</div>
												<div className={cn(styles.resourcesHeaderIcon, styles.resourcesHeaderIconFilter)}>
													<div
														className={cn(styles.sortByDate)}
														onClick={() => setDropStatus(!dropStatus)}
													>
														<div> {status[dropStatusValue]} </div>
														<SvgSprite
															spriteID={"arrow"}
															className={cn(styles.arrowStyle, styles.iconMargin)}
														/>
													</div>
													{dropStatus && (
														<div
															ref={dropdownRefStatus}
															className={cn(styles.actionsContent, styles.dropResourcActionsContent)}
														>
															{Object.keys(status).map(s => (
																<div
																	key={s}
																	className={cn(styles.dropResourceBtn)}
																	onClick={() => {
																		setDropStatusValue(s)
																		setDropStatus(false)
																	}}
																>
																	<div>{status[s]}</div>
																	{dropStatusValue === s && (
																		<SvgSprite
																			spriteID={"check"}
																			className={styles.checkStyles}
																		/>
																	)}
																</div>
															))}
														</div>
													)}
												</div>
												<div className={cn(styles.resourcesHeaderIcon, styles.resourcesHeaderIconFilter)}>
													<div
														className={cn(styles.sortByDate)}
														onClick={() => setDropType(!dropType)}
													>
														<div> {types[dropTypeValue]} </div>
														<SvgSprite
															spriteID={"arrow"}
															className={cn(styles.arrowStyle, styles.iconMargin)}
														/>
													</div>
													{dropType && (
														<div
															ref={dropdownRefType}
															className={cn(styles.actionsContent, styles.dropResourcActionsContent)}
														>
															{Object.keys(types).map(type => (
																<div
																	key={type}
																	className={cn(styles.dropResourceBtn)}
																	onClick={() => {
																		setDropTypeValue(type)
																		setDropType(false)
																	}}
																>
																	<div> {types[type]} </div>
																	{dropTypeValue === type && (
																		<SvgSprite
																			spriteID={"check"}
																			className={styles.checkStyles}
																		/>
																	)}
																</div>
															))}
														</div>
													)}
												</div>
											</div>
										)}
									</div>

									{dropEvent && (
										<div
											ref={dropdownRef}
											className={cn(styles.actionsContent, styles.dropResourcActionsContent)}
										>
											{Object.keys(types).map((t, i) => (
												<div
													key={i}
													className={cn(styles.dropResourceBtn)}
													onClick={() => {
														setDropStatusValue(t)
													}}
												>
													<div> {types[t]} </div>
													{dropTypeValue === t && (
														<SvgSprite
															spriteID={"check"}
															className={styles.checkStyles}
														/>
													)}
												</div>
											))}
										</div>
									)}
								</div>
							</div>
							<div className={styles.flexRow}>
								{isCalendarView ? (
									<img
										src={list}
										alt="list"
										className={styles.btnSwitchViewMode}
										onClick={() => setIsCalendarView(false)}
									/>
								) : (
									<img
										src={calendar}
										alt="calendar"
										className={styles.btnSwitchViewMode}
										onClick={() => setIsCalendarView(true)}
									/>
								)}
								<Btn
									className={styles.btnCreateEvent}
									onClick={() => setAddEventPopup(true)}
								>
									Create event
								</Btn>
							</div>
						</div>
						{isCalendarView ? (
							<FullCalendarWrapper>
								<FullCalendar
									plugins={[interactionPlugin, dayGridPlugin, timeGridDay, timeGridWeek]}
									timeZone={"local"}
									events={events || []}
									eventContent={renderEventContent}
									editable={true}
									selectable={true}
									eventClick={e => {
										if (e.event._def.publicId?.length > 0) {
											setAddEventPopup(true)
											setNewEvent(events.find(ev => ev.id === (e.id || parseInt(e.event._def.publicId))))
											setUpdateEvent(true)
										}
									}}
									select={e => {
										setAddEventPopup(true)
										setNewEvent(e)
										setUpdateEvent(false)
									}}
									eventDrop={handleEventDrop}
									viewClassNames={{
										height: 800,
										width: "94%",
										margin: "auto",
										backgroundColor: "#F7F9FB",
										marginTop: "20px",
										borderRadius: "10px",
									}}
									headerToolbar={{
										left: "today prev,next",
										center: "title",
										right: "dayGridMonth,timeGridWeek,timeGridDay",
									}}
									height={"100vh"}
									dayMaxEvents={true}
									businessHours={true}
									buttonText={{
										dayGridMonth: "Month",
										timeGridWeek: "Week",
										timeGridDay: "Day",
										today: "Today",
									}}
								/>
							</FullCalendarWrapper>
						) : (
							<CalendarWidget
								allEvents={allEvents}
								setDropSort={setDropSort}
								sortBy={sortBy}
								events={events || []}
								setEvents={setEvents}
								setDropStatus={setDropStatus}
								setDropType={setDropType}
								dropStatusValue={dropStatusValue}
								dropTypeValue={dropTypeValue}
								dropdownRef={dropdownRefList}
								dropdownRefStatus={dropdownRefStatus}
								dropdownRefType={dropdownRefType}
							/>
						)}

						{addEventPopup && (
							<Popup
								calendar
								isOpenedPopup={addEventPopup}
								closePopup={() => {
									setAddEventPopup(false)
									setUpdateEvent(null)
									setNewEvent(null)
								}}
							>
								<AddEventComponent
									closePopup={() => {
										setAddEventPopup(false)
										setUpdateEvent(null)
										setNewEvent(null)
									}}
									title={"Add Event"}
									newEvent={newEvent || stateData?.event}
									setEvents={setEvents}
									events={events}
									startAccessor="start"
									endAccessor="end"
									updateEvent={updateEvent || stateData?.event}
									stateData={stateData?.event ? null : stateData}
									openWithData={openWithData}
									report_id={stateData?.report_id}
									report_title={stateData?.title}
									report={stateData?.report_id || stateData?.report}
									setReturnReportId={setReturnReportId}
								/>
							</Popup>
						)}
					</div>
				</div>
			)}
			{addSettingPopup && (
				<Popup
					extraBig
					grayBackground
					isOpenedPopup={addSettingPopup}
					closePopup={() => setAddSettingPopup(false)}
				>
					<SettingModel
						closePopup={() => setAddSettingPopup(false)}
						plan={plan}
						setPlan={setPlan}
					/>
				</Popup>
			)}
		</div>
	)
}

function renderEventContent(eventInfo) {
	const backgroundColor = eventInfo.event._def.extendedProps.colorEvent

	const style = {
		height: "100%",
		width: "100%",
		background: "linear-gradient(to right, " + backgroundColor + " 5px, white 5px)",
		fontWeight: "bold",
		fontSize: "10px",
		borderRadius: "4px",
		color: "#E0E0E0",
		textAlign: "left",
		letterSpacing: "0px",
		opacity: 1,
		border: "0px",
		display: "block",
		padding: "6px 0px 6px 10px",
		boxShadow: "0px 2px 5px rgb(38 51 77 / 15%)",
		initialView: "timeline",
		duration: {
			days: 1,
		},
	}

	return (
		<div style={style}>
			<b>{eventInfo.timeText}</b> <i>{eventInfo.event.title}</i>
		</div>
	)
}
