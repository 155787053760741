import React, { useEffect, useState } from "react"
import { axiosInstance } from "../../config/https"
import { toast } from "../../functions/toast"
import styles from "./index.module.scss"
import { SvgSprite } from "../../ui/SvgSprite"
import { useDispatch } from "react-redux"
import { Loader } from "../../ui/Loader"
import { ProfileImage } from "../../ui/ProfileImage/ProfileImage"

export const InvitedUsers = ({ access }) => {
	// const [loader, setLoader] = useState(false)
	const [loaderRemove, setLoaderRemove] = useState(false)
	const [UserInvited, setUserInvited] = useState([])
	const dispatch = useDispatch()
	const [selectedUsers, setSelectedUsers] = React.useState([])
	const [selectAll, setSelectAll] = React.useState(false)

	const svgDeselect = (
		<SvgSprite
			className={styles.svgIconsEmpty}
			spriteID={"list"}
		></SvgSprite>
	)
	const svgSelect = (
		<SvgSprite
			className={styles.CustomSvgIcons}
			spriteID={"check"}
		></SvgSprite>
	)

	const handleRemove = async () => {
		setLoaderRemove(true)
		const selectedUsersIds = selectedUsers.map(user => user.id)
		const restoreRequests = selectedUsersIds.map(async id => {
			await axiosInstance.delete(`/invited/${id}`).catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
			})
		})
		await Promise.all(restoreRequests).then(() => {
			getInvitedUser()
			dispatch(toast("success", "Invite(s) Removed Successfully"))
		})
		setLoaderRemove(false)
	}

	const handleUserSelection = user => {
		let newSelectedUsers = [...selectedUsers]
		if (user === "all") {
			if (selectAll) {
				newSelectedUsers = []
			} else {
				newSelectedUsers = UserInvited
			}
			setSelectAll(!selectAll)
		} else {
			const selectedIndex = selectedUsers.indexOf(user)

			if (selectedIndex === -1) {
				newSelectedUsers.push(user)
			} else {
				newSelectedUsers.splice(selectedIndex, 1)
			}
			setSelectAll(newSelectedUsers.length === UserInvited.length)
		}
		setSelectedUsers(newSelectedUsers)
	}

	const getInvitedUser = async () => {
		await axiosInstance("invited")
			.then(res => {
				setUserInvited(res.data.invited_users)
			})
			.catch(err => {
				dispatch(toast("erorr", err?.response?.data?.message))
			})
			.finally(() => {
				// setLoader(false)
			})
	}

	useEffect(() => {
		(async () => {
			Promise.all([getInvitedUser()])
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	return (
		<div className={styles.BodyUserContainerInvited}>
			<div className={styles.headerUserSection}>
				<ul style={{ display: "flex", alignItems: "center", gap: "20px", padding: "12px" }}>
					<li onClick={() => handleUserSelection("all")}>{selectAll ? svgSelect : svgDeselect}</li>
					<li style={{ color: "#E0E0E0" }}>Select All</li>
				</ul>
				<div className={styles.menuUser}>
					{/* table  */}
					<ul className={styles.tableRow}>
						<li className={styles.tableCell}>
							<div
								style={{ display: "flex", cursor: "pointer" }}
								onClick={() => {
									if (window.confirm("Are you sure you wish to delete this user?")) handleRemove()
								}}
							>
								<SvgSprite
									className={styles.svgIcons}
									spriteID={"remove"}
								></SvgSprite>
								{loaderRemove ? <Loader /> : <p>Delete</p>}
							</div>
						</li>
					</ul>
				</div>
				<ul></ul>
			</div>
			<div className={styles.bodyContainer}>
				<table className={styles.tableContent}>
					<thead className={styles.theadStyles}>
						<tr>
							<th>
								<SvgSprite
									className={styles.svgIconsEmpty}
									spriteID={"list"}
								></SvgSprite>
							</th>
							<th></th>
							<th>Name</th>
							<th style={{ textAlign: "left" }}>Email</th>
							<th style={{ textAlign: "left" }}>Role</th>
						</tr>
					</thead>

					<tbody className={styles.tbodyStyles}>
						{UserInvited?.length > 0 &&
							UserInvited?.map((user, index) => (
								<tr key={index}>
									{!access && (
										<td onClick={() => handleUserSelection(user)}>
											{selectedUsers.includes(user) ? svgSelect : svgDeselect}
										</td>
									)}
									<td>
										<ProfileImage name={user.fullName || ""} />
									</td>
									<td>{user?.fullName}</td>
									<td>{user?.email}</td>
									<td>{user?.role}</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	)
}
